import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { easeOut, motion } from 'framer-motion';
import './Navbar.scss';



const Navbar = () => {

  const [taggle, setTaggle] = useState(false)
  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <h1>MU<b>NS</b>HA</h1>
      </div>
      <ul className='app__navbar-links'>
        {["home", "about", "work", "skills", "contact"].map((item)=>(
          <li className='app__flex p-text' key={`link${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-menu">
          <HiMenuAlt4 onClick={()=> setTaggle(true)}/>
          {taggle && (
            <div
            className='animation'
              whileInView={{x: [300, 0]}}
              transition={{duration: 0.85, ease: 'easeOut'}}
            >
              <HiX onClick={()=> setTaggle(false)}/>
               <ul>
                  {["home", "about", "work", "skills", "contact"].map((item)=>(
                  <li key={item}>
                    <a onClick={()=>setTaggle(false)} href={`#${item}`}>{item}</a>
                  </li>
                ))}
                </ul>
            </div>
          )}
      </div>
    </nav>
  )
}

export default Navbar;