import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {

  const [formData, setFormData] = useState({ name:'', email:'', message:''});

  const [isFormSubmited, setIsFormSubmited] = useState(false);

  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({...formData, [name]: value})
  }
  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message,
    }

    client.create(contact).then(()=>{
      setLoading(false);
      setIsFormSubmited(true)
    })

  }
  return (
    <>
      <h2 className="head-text">Get In Touch & Lets Have A Chat With Me.</h2>

      <div className="app__footer-cards">
        
        <div className="app__footer-card">
          <img src={images.mail} alt="email" />
          <a href="mailto:evansmunshawebdeveloper@gmail.com" className='p-text1'>munshadeveloper@gmail.com</a>
        </div>

        <div className="app__footer-card">
          <img src={images.tel} alt="mobile" />
          <a href="tel: +260 963 266 937" className='p-text'>+260 963 266 937</a>
        </div>

      {
        !isFormSubmited ? 
        <div className="app__footer-form app__flex">
          
          <div className="app__flex">
            <input type="text" 
            className="p-text" placeholder="What's your Name" value={name} name='name' onChange={handleChangeInput}/>
          </div>

          <div className="app__flex">
            <input type="email" 
            className="p-text" placeholder="What's your Email" value={email} name='email' onChange={handleChangeInput}/>
          </div>

          <div>
            <textarea 
            className='p-text'
            name='message'
            value={message}
            onChange={handleChangeInput}
            id="" 
            cols="30" 
            rows="10"
            placeholder='Your message'
            >

            </textarea>
          </div>
          <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending' : 'Send'}</button>
        </div>

        : 
        
        <h3 className='head-text'>Thank you for getting in touch!</h3>

      }
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);