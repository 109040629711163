import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import {  FaLinkedin  } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    {/*<div>
      <BsTwitter />
    </div>
    <div>
      <FaFacebookF />
    </div>*/
    }
    <div>
      <a style={{display:'flex', alignItems:'center',justifyContent:'center'}} href="https://www.linkedin.com/in/evansmunsha-webdeveloper-131b322a0/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
      
    </div>
  </div>
);

export default SocialMedia;
