import mail from '../assets/mail.png';
import tel from '../assets/tel.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';






import circle from '../assets/circle.svg';
import codework from '../assets/codework.png';

export default {
  tel,
  mail,
  react,
  redux,
  sass,
  codework,
  circle,

};
